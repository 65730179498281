console.clear();

$(document).ready(function () {
  $("a[href^='#']").click(function (e) {
    e.preventDefault();

    var position = $($(this).attr("href")).offset().top;

    $("body, html").animate(
      {
        scrollTop: position,
      },
      500,
      "linear"
    );
  });
});

// Replace SVG icons with inline SVG
$(".social").each(function () {
  console.log("elo");
  var $img = $(this);
  var imgClass = $($img).attr("class");
  var imgURL = $($img).attr("src");

  $.get(
    imgURL,
    function (data) {
      // Get the SVG tag, ignore the rest
      var $svg = $(data).find("svg");

      // Add replaced image's classes to the new SVG
      if (typeof imgClass !== "undefined") {
        $svg = $svg.attr("class", imgClass + " replaced-svg");
      }

      // Remove any invalid XML tags as per http://validator.w3.org
      $svg = $svg.removeAttr("xmlns:a");

      // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
      if (!$svg.attr("viewBox") && $svg.attr("height") && $svg.attr("width")) {
        $svg.attr(
          "viewBox",
          "0 0 " + $svg.attr("height") + " " + $svg.attr("width")
        );
      }

      // Replace image with new SVG
      $img.replaceWith($svg);
    },
    "xml"
  );
});

//progress-bar
// document.addEventListener('scroll', _ => {
//     var docElem = document.documentElement;
//     var docBody = document.body;
//     var scrollTop = (docBody.scrollTop || docElem.scrollTop);
//     var height = docElem.scrollHeight - docElem.clientHeight;

//     progress = scrollTop / height * 100;

//     if (progress > 0) {
//       progressBar = document.querySelector('#progress-bar');
//       var bgVal = '';
//       bgVal = 'linear-gradient(to right,' + $brand-color + ' ' + progress + '%, transparent 0)';
//       progressBar.style.background = bgVal;
//     } else {
//       var bgVal = 'linear-gradient(to right, ' + $brand-color + ' 0%, transparent 0)';
//       progressBar.style.background = bgVal;
//     }
//   })


window.addEventListener("load", function() {
const form = document.getElementById('my-form');
form.addEventListener("submit", function(e) {
  console.log('yes');
e.preventDefault();
const data = new FormData(form);
const action = e.target.action;
fetch(action, {
method: 'POST',
body: data,
})
.then(() => {
alert("I got it from here, await an email from me!");
})
});
});
